import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Segment, Loader, Header, Message, Checkbox } from "semantic-ui-react";
import PageContainer from "components/lib/PageContainer";
import NotValidOption from "components/SiteManage/Content/lib/NotValidOption";
import OptionTakeoutStatus from "components/lib/OptionTakeoutStatus";
import { takeoutRepository } from "domain/repository";
import { UserContainer, StoreContainer } from "containers";
import { AGENCY_OPTION, StoreIdParam } from "config/raven";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";
import EcSettingForm from "components/SiteManage/Content/Takeout/EcSettingForm";
import InvoiceSettingForm from "components/SiteManage/Content/Takeout/InvoiceSettingForm";
import TakeoutAndEcCommonSettingForm from "./TakeoutAndEcCommonSettingForm";

const AGENCY: AGENCY_OPTION = require("config/agency.json");

const Container: React.FC = () => {
  const { isAgency } = UserContainer.useContainer();
  const { storeId } = useParams<StoreIdParam>();
  const { currentStore, changeCurrentStore } = StoreContainer.useContainer();

  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const agencyEnabled = AGENCY[window.location.hostname].options.takeout;

  if (!agencyEnabled) {
    return <NotValidOption heading="テイクアウト機能" />;
  }

  if (!currentStore || loading) {
    return <Loader active />;
  }

  const handleClickEnable = (useAsEc: boolean) => {
    if (!storeId) {
      return;
    }
    setHasError(false);
    setLoading(true);
    takeoutRepository
      .enable(isAgency, storeId, useAsEc)
      .then(() => changeCurrentStore(isAgency, storeId))
      .catch(() => {
        setHasError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const {
    useTakeout,
    takeoutUseAsEc,
    takeoutUseEligibleInvoice,
    ecCodFee,
    ecDefaultShippingFee,
    ecPrefectureShippingFeesJson,
    takeoutIssuerEnterpriseName,
    takeoutIssuerRegistrationNumber,
    takeoutNotesCart,
    takeoutNotesConfirm,
  } = currentStore.property;

  return (
    <>
      <Header content="テイクアウト機能オプション利用状況" dividing />
      <OptionTakeoutStatus
        enabled={useTakeout}
        toEnable={handleClickEnable}
        isAgency={isAgency}
      />
      <br />
      {hasError && (
        <Message
          error
          content="テイクアウト機能の有効化に失敗しました。テイクアウト機能を利用できない場合は、運営にお問い合わせください。"
        />
      )}
      {useTakeout && (
        <>
          <TakeoutAndEcCommonSettingForm
            initialValues={{
              takeoutNotesCart: takeoutNotesCart ?? "",
              takeoutNotesConfirm: takeoutNotesConfirm ?? "",
            }}
            currentStore={currentStore}
            handleSubmit={async ({ values }) => {
              await takeoutRepository.setTakeoutAndEcCommonSettings(
                isAgency,
                storeId,
                values.takeoutNotesCart,
                values.takeoutNotesConfirm
              );
              window.location.reload();
              alert("設定を更新しました");
            }}
          />
          {takeoutUseAsEc && (
            <>
              <div css={{ marginTop: 40 }}>
                <Checkbox
                  checked={takeoutUseAsEc}
                  label="簡易EC機能として利用"
                  disabled
                />
              </div>
              <EcSettingForm
                initialValues={{
                  ecCodFee: ecCodFee?.toString() ?? "0",
                  shippingFee: ecDefaultShippingFee?.toString(),
                  ecPrefectureShippingFeesJson:
                    ecPrefectureShippingFeesJson?.toString(),
                }}
                currentStore={currentStore}
                handleSubmit={async ({ values }) => {
                  // 基本送料が変更された場合
                  if (parseInt(values.shippingFee) !== ecDefaultShippingFee) {
                    await takeoutRepository.setEcDefaultShippingFee(
                      isAgency,
                      storeId,
                      parseInt(values.shippingFee) || 0
                    );
                  }
                  // 都道府県別送料が変更された場合
                  if (
                    values.ecPrefectureShippingFeesJson !==
                    ecPrefectureShippingFeesJson
                  ) {
                    await takeoutRepository.setEcPrefectureShippingFee(
                      isAgency,
                      storeId,
                      values.ecPrefectureShippingFeesJson || "{}"
                    );
                  }
                  // 代引き手数料が変更された場合
                  if (parseInt(values.ecCodFee) !== ecCodFee) {
                    await takeoutRepository.setEcCodFee(
                      isAgency,
                      storeId,
                      parseInt(values.ecCodFee) || 0
                    );
                  }
                  window.location.reload();
                  alert("設定を更新しました");
                }}
              />
            </>
          )}
          <br />
          <>
            <InvoiceSettingForm
              initialValues={{
                useInvoice: takeoutUseEligibleInvoice,
                issuerName: takeoutIssuerEnterpriseName?.toString(),
                issuerNumber: takeoutIssuerRegistrationNumber?.toString(),
              }}
              currentStore={currentStore}
              handleSubmit={({ values }) => {
                takeoutRepository
                  .setInvoiceSettings(
                    isAgency,
                    storeId,
                    values.useInvoice,
                    values.issuerName,
                    values.issuerNumber
                  )
                  .then(() => {
                    window.location.reload();
                    alert("適格簡易請求書の設定を更新しました");
                  })
                  .catch((e) => {
                    alert(e.response.data.message);
                  });
              }}
            />
          </>
        </>
      )}
    </>
  );
};

const Takeout = () => {
  const { isAgency } = UserContainer.useContainer();
  const { storeId } = useParams<StoreIdParam>();

  return (
    <PageContainer
      header="テイクアウト機能"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .siteManagePage()
        .build("テイクアウト機能")}
    >
      <Segment padded>
        <Container />
      </Segment>
    </PageContainer>
  );
};

export default Takeout;
