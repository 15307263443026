import { Type, Expose, Transform } from "class-transformer";
import AwsResource from "domain/model/AwsResource";
import dayjs from "dayjs";
import { AwsStatus } from "config/raven";

type AgencyContent = {
  id: string;
  name: string;
};

export default class Store {
  @Expose() id: number;
  @Expose() name: string;
  @Expose() address: string;
  @Expose({ name: "aws_state" }) awsState: AwsStatus;
  @Expose() email: string;
  @Expose({ name: "head_version" }) headVersion: string;
  @Expose() prefecture: string;
  @Expose() tel: string;
  @Expose() fax: string;
  @Expose({ name: "has_line_access_token" }) hasLineAccessToken: boolean;
  @Expose({ name: "is_deleted" }) isDeleted: boolean;

  @Expose({ name: "updated_at" })
  @Transform((value) => dayjs(value).format("YYYY/MM/DD"))
  updatedAt: Date;

  @Expose({ name: "agency" })
  agency: AgencyContent;

  @Type(() => Property)
  @Expose()
  property: Property;

  @Type(() => AwsResource)
  @Expose({ name: "aws_resource" })
  awsResource: AwsResource;
}

class Property {
  @Expose({ name: "use_sitest" }) useSitest: boolean;
  @Expose({ name: "use_dns" }) useDns: boolean;
  @Expose({ name: "use_recruit" }) useRecruit: boolean;
  @Expose({ name: "use_tabelog" }) useTabelog: boolean;
  @Expose({ name: "tabelog_url" }) tabelogUrl: string;
  @Expose({ name: "retty_url" }) rettyUrl: string;
  @Expose({ name: "subsc_url" }) subscUrl: string;
  @Expose({ name: "tabelog_synced_at" }) tabelogSyncedAt: string;
  @Expose({ name: "retty_synced_at" }) rettySyncedAt: string;
  @Expose({ name: "subsc_synced_at" }) subscSyncedAt: string;
  @Expose({ name: "use_takeout_dashboard" }) useTakeoutDashboard: boolean;
  @Expose({ name: "use_takeout" }) useTakeout: boolean;
  @Expose({ name: "sitest_id" }) sitestId: string;
  @Expose({ name: "sitest_shop_id" }) sitestShopId: string;
  @Expose({ name: "takeout_minimum_lead_time" }) takeoutMinimumLeadTime: number;
  @Expose({ name: "takeout_use_as_ec" }) takeoutUseAsEc: boolean;
  @Expose({ name: "ec_default_shipping_fee" }) ecDefaultShippingFee: number;
  @Expose({ name: "ec_prefecture_shipping_fees_json" })
  ecPrefectureShippingFeesJson: string;
  @Expose({ name: "ec_cod_fee" }) ecCodFee: number;
  @Expose({ name: "takeout_use_eligible_invoice" })
  takeoutUseEligibleInvoice: boolean;
  @Expose({ name: "takeout_issuer_enterprise_name" })
  takeoutIssuerEnterpriseName: string;
  @Expose({ name: "takeout_issuer_registration_number" })
  takeoutIssuerRegistrationNumber: string;
  @Expose({ name: "has_takeout_stripe_secret" })
  hasTakeoutStripeSecret: boolean;
  @Expose({ name: "use_resty" }) useResty: boolean;
  @Expose({ name: "use_instagram" }) useInstagram: boolean;
  @Expose({ name: "takeout_notes_cart" }) takeoutNotesCart: string;
  @Expose({ name: "takeout_notes_confirm" }) takeoutNotesConfirm: string;
}
