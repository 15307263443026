import React from "react";
import { Segment, Form, Button } from "semantic-ui-react";

import { useFormik } from "formik";
import * as Yup from "yup";

import Store from "domain/model/Store";

type FormValues = {
  takeoutNotesCart: string;
  takeoutNotesConfirm: string;
};

const validationSchema = Yup.object().shape({});

type Props = {
  initialValues: FormValues;
  currentStore: Store;
  handleSubmit: ({
    values,
    resetForm,
  }: {
    values: FormValues;
    resetForm: () => void;
  }) => void;
};

const TakeoutAndEcCommonSettingForm: React.FC<Props> = ({
  initialValues,
  handleSubmit,
}) => {
  const formik = useFormik({
    initialValues,
    onSubmit: (values, { resetForm }) => {
      handleSubmit({ values, resetForm });
    },
    initialStatus: { success: "", error: "" },
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Segment>
        <Form.Field>
          <label>備考（カート画面表示用）</label>
          <Form.TextArea
            name="takeoutNotesCart"
            error={formik.errors.takeoutNotesCart}
            value={formik.values.takeoutNotesCart}
            onChange={formik.handleChange}
            placeholder="必要に応じてプライバシーポリシー・特商法の案内ページなどのリンクを記載してください（HTML可）"
          />
        </Form.Field>
        <Form.Field>
          <label>備考（最終確認画面表示用）</label>
          <Form.TextArea
            name="takeoutNotesConfirm"
            error={formik.errors.takeoutNotesConfirm}
            value={formik.values.takeoutNotesConfirm}
            onChange={formik.handleChange}
            placeholder="必要に応じて引渡・提供時期の情報を記載してください（HTML可）"
          />
        </Form.Field>
        <div style={{ textAlign: "center" }}>
          <Button color="blue" content="更新" type="submit" />
        </div>
      </Segment>
    </Form>
  );
};

export default TakeoutAndEcCommonSettingForm;
