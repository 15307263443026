import api from "utils/api";
import endpoint from "utils/endpoint";

class TakeoutRepository {
  /**
   * 代理店ユーザーのみ利用可能
   * テイクアウト機能を有効化する
   */
  public async enable(isAgency: boolean, storeId: string, useAsEc: boolean) {
    const url = endpoint.agency.takeout.use(storeId);
    return api.post(url, { as_ec: useAsEc });
  }

  public async setEcDefaultShippingFee(
    isAgency: boolean,
    storeId: string,
    ecDefaultShippingFee: number | null
  ) {
    const url =
      isAgency && storeId
        ? endpoint.agency.takeout.ecShippingFee(storeId)
        : endpoint.store.takeout.ecShippingFee();
    return api.post(url, { shipping_fee: ecDefaultShippingFee });
  }

  public async setEcPrefectureShippingFee(
    isAgency: boolean,
    storeId: string,
    ecPrefectureShippingFeesJson: string
  ) {
    const url =
      isAgency && storeId
        ? endpoint.agency.takeout.ecPrefectureShippingFees(storeId)
        : endpoint.store.takeout.ecPrefectureShippingFees();
    return api.post(url, {
      prefecture_shipping_fees_json: ecPrefectureShippingFeesJson,
    });
  }

  public async setEcCodFee(
    isAgency: boolean,
    storeId: string,
    ecCodFee: number | null
  ) {
    const url =
      isAgency && storeId
        ? endpoint.agency.takeout.ecCodFee(storeId)
        : endpoint.store.takeout.ecCodFee();
    return api.post(url, { cod_fee: ecCodFee });
  }

  public async setInvoiceSettings(
    isAgency: boolean,
    storeId: string,
    useInvoice: boolean,
    takeoutIssuerEnterpriseName: string | null,
    takeoutIssuerRegistrationNumber: string | null
  ) {
    const url =
      isAgency && storeId
        ? endpoint.agency.takeout.invoiceSettings(storeId)
        : endpoint.store.takeout.invoiceSettings();
    return api.post(url, {
      use_invoice: useInvoice,
      issuer_name: takeoutIssuerEnterpriseName,
      issuer_number: takeoutIssuerRegistrationNumber,
    });
  }

  public async setTakeoutAndEcCommonSettings(
    isAgency: boolean,
    storeId: string,
    takeoutNotesCart: string,
    takeoutNotesConfirm: string
  ) {
    const url =
      isAgency && storeId
        ? endpoint.agency.takeout.commonSettings(storeId)
        : endpoint.store.takeout.commonSettings();
    return api.post(url, {
      takeout_notes_cart: takeoutNotesCart,
      takeout_notes_confirm: takeoutNotesConfirm,
    });
  }
}

export default new TakeoutRepository();
